import { ActionContext } from 'vuex';
import { State } from '@/store/index';
import axios from 'axios';
import * as Config from '@/config';
import { useAuthService } from '@/shared/AuthService'
import { router } from '@/router';
const authService = useAuthService();

type Context = ActionContext<IPachincos, State>;

export type Pachinco = {
  id: string,
  serial: string,
  ip: string,
  email: string,
  registered_at: string
}

export interface IPachincos {
  items: Array<Pachinco>
}

export default { 
  namespaced: true,
  state: (): IPachincos => ({ items: Array<Pachinco>() }),
  getters: {
    getPachincos: (state: { items: Array<Pachinco> }): Array<Pachinco> => {
      return state.items;
    }
  },
  mutations: {
    UPDATE_PACHINCOS(state: IPachincos, pachincos: Array<Pachinco>): void {
      state.items = pachincos;
    }
  },
  actions: {
    updatePachincos: async (context: Context): Promise<void> => {
      const headers = { Authorization: authService.getAccessToken() };
      try {
        const res = await axios.get(`${Config.Config.BotConfigAPI}/pachinco/admin`, { headers });
        context.commit('UPDATE_PACHINCOS', res.data);
      } catch(error){
        authService.removeLoginInfo()
        router.push('/login')
      }
    }
  }
}
