






import { Component, Prop, Vue } from 'vue-property-decorator'
import Login from '@/components/Login.vue'

@Component({
  components: {
    Login
  }
})
export default class LoginView extends Vue { 
  @Prop() private username!: string
}
