
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import * as Config from '@/config'
import axios from 'axios'
import store from '@/store';
import { useAuthService } from '@/shared/AuthService'
const authService = useAuthService();

@Component
export default class BotRequest extends Vue {
  @Prop() readonly ip!: string
  @Provide() overlay = false
  @Provide() options =  [
        { text: '------- 거래소 선택 -------', value: '' },
        { text: 'Okx(www.okx.com)', value: 'okx'},
        { text: 'Binance(www.binance.com)', value: 'binance' },
        { text: '업비트', value: 'upbit' },
        { text: 'FTX', value: 'FTX', disabled: true },
        { text: 'Bybit', value: 'bybit', disabled: true },
        { text: 'Bitget', value: 'bitget', disabled: true },
  ]
  @Provide() nickname = ''
  @Provide() exchange = ''
  @Provide() exchange_user_id = ''
  @Provide() key = ''
  @Provide() secret = ''
  @Provide() password = ''

  async registerBot() : Promise<void> {
    if (this.nickname === '' || this.exchange === '') {
      alert('봇 이름을 입력해 주세요');
      return
    }
    const data = {
      "nickname": this.nickname,
      "exchange": this.exchange,
      "exchange_user_id": this.exchange_user_id
    };
    const headers = { Authorization: authService.getAccessToken() };
    this.overlay = true;
    try {
      const res = await axios.post(`${Config.Config.BotConfigAPI}/bot/register`, data, { headers });
      if (res.data.status === 'success') {
        await store.dispatch('bots/updateBots');
        this.$emit('botRegistered');
        // this.exchange = '';
        this.nickname = '';
        this.exchange_user_id = '';
      } else {
        alert("뭔가 잘못 되었습니다. BotRequest line 46");
        if (res.status === 401) {
          authService.removeLoginInfo();
          this.$router.push('/login');
        }
      }
      this.overlay = false;
    } catch (error) {
      console.log(error);
      this.overlay = false;
    }
  }
}
