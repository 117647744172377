
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import * as Config from '@/config'
import axios from 'axios'
import store from '@/store';
import { useAuthService } from '@/shared/AuthService'
const authService = useAuthService();

type PachincoItem = { 
    id: number, 
    serial: string, 
    ip: string,
    email: string,
    registered_at: Date
}
@Component
export default class PachincoRegister extends Vue {
  @Prop() readonly ip!: string
  @Provide() overlay = false
  @Provide() serial = ''
  @Provide() admin = false
  @Provide() items = []
  @Provide() fields = [
    {
      key: 'index',
      label: '번호'
    },
    {
      key: 'serial',
      label: '등록번호'
    },
    {
      key: 'ip',
      label: 'IP주소'
    },
    {
      key: 'email',
      label: '연락처'
    },
    {
      key: 'registered_at',
      label: '등록일시',
      formatter: (value: string, key: string, item: PachincoItem): string => {
        return new Date(item.registered_at+".000Z").toLocaleString()
      }
    }
  ]

  async mounted (): Promise<void> {
    const info = authService.getLoginInfo();
    this.admin = info['admin'];
    await store.dispatch('pachincos/updatePachincos');
    this.items = this.$store.state.pachincos.items.data;
  }

  async registerPachinco() : Promise<void> {
    if (this.serial === '') {
      alert('제품등록번호를 입력해 주세요');
      return
    }
    const data = {
      "serial": this.serial,
      "ip": "",
      "email": ""
    };
    const headers = { Authorization: authService.getAccessToken() };
    this.overlay = true;
    try {
      const res = await axios.post(`${Config.Config.BotConfigAPI}/pachinco/admin`, data, { headers });
      if (res.data.status === 'success') {
        await store.dispatch('pachincos/updatePachincos');
        this.items = store.getters['pachincos/getPachincos']['data'];
        this.serial = '';
      } else {
        alert("뭔가 잘못 되었습니다. PachincoRegister line 34");
        if (res.status === 401) {
          authService.removeLoginInfo();
          this.$router.push('/login');
        }
      }
      this.overlay = false;
    } catch (error) {
      console.log(error);
      this.overlay = false;
    }
  }
  async removePachinco(item: PachincoItem): Promise<void> {
    this.overlay = true;
    console.log(item);
    try {
      const headers = { Authorization: authService.getAccessToken() }
      await axios.put(`${Config.Config.BotConfigAPI}/bot/retract/${item.id}`, { headers });
      this.overlay = false;
    } catch(err) {
      console.log(err);
      this.overlay = false;
    } 
  }
}
