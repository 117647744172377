import axios from 'axios';
const BOT_CONFIG_LOGIN_INFO = 'botConfigLoginInfo';
import * as Config from '@/config';
import store from '@/store';

export type Info = {
  accessToken: string,
  refreshToken: string,
  autoRefresh: boolean,
  admin: boolean
}
type Auth = {
  url: string,
  username: string,
  password: string
}

export class AuthService {
  storeLoginInfo(info: Info): void {
    localStorage.setItem(BOT_CONFIG_LOGIN_INFO, JSON.stringify(info));
  }
  getLoginInfo(): Info {
    const info = JSON.parse(localStorage.getItem(BOT_CONFIG_LOGIN_INFO) || '{"accessToken": ""}');
    return info;
  }
  getAccessToken(): string {
    return this.getLoginInfo().accessToken;
  }
  loggedIn(): boolean {
    return this.getLoginInfo().accessToken !== "";
  }
  async logout(): Promise<void> {
    const headers = { Authorization: this.getAccessToken() };
    this.removeLoginInfo();
    try {
      await axios.post(`${Config.Config.BotConfigAPI}/auth/logout`, {}, { headers });
      location.reload();
    } catch (error) {
      console.log(error)
    }
  }
  removeLoginInfo(): void {
    console.log('removing login');
    localStorage.setItem(BOT_CONFIG_LOGIN_INFO, JSON.stringify({ accessToken: "" }));
  }
  async login(auth: Auth): Promise<boolean> {
    //  Login using username / password
    try {
      const { data } = await axios.post( `${auth.url}`,
        { username: auth.username, password: auth.password });
      if (data.status === 'success') {
        const obj = {
          accessToken: data.Authorization || "",
          refreshToken: data.refresh_token || "",
          autoRefresh: true,
          admin: data.admin,
        };
        this.storeLoginInfo(obj);
        await store.dispatch('user/loggedIn', obj);
        return true;
      } else {
        return false;
      }
    } catch(error) {
      console.log(error);
      return false
    }
  }
}
export function useAuthService(): AuthService {
  const authService = new AuthService();
  return authService;
}
