import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/bootstrap-vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'document-register-element/build/document-register-element'
import VueCustomElement from 'vue-custom-element'
import App from './App.vue'
import { router } from '@/router';

Vue.config.productionTip = false
Vue.use(VueCustomElement)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.customElement('bits-bee', new App({router: router}).$options)

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
