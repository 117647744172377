import { ActionContext } from 'vuex';
import { State } from '@/store/index';
// import { useAuthService } from '@/shared/AuthService'
// import { router } from '@/router';
// const authService = useAuthService();
type Context = ActionContext<IUser, State>;

type User = {
  accessToken: string,
  refreshToken: string,
  autoRefresh: boolean,
  admin: boolean,
  email: string,
  id: number
}
export interface IUser {
  user: User
}

export default {
  namespaced: true,
  state: (): IUser => ({ 
    user: { 
      accessToken: '', 
      refreshToken: '',
      autoRefresh: false,
      admin: false,
      email: '',
      id: 0
    }
  }),

  mutations: { 
    LOGGED_IN(state: IUser, user: User): void {
      state.user = user;
    }
  },

  actions: {
    loggedIn: async (context: Context, user: User): Promise<void> => {
      context.commit('LOGGED_IN', user)
    }
  }
}