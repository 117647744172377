











import { Component, Prop, Vue } from 'vue-property-decorator';
import { router } from '@/router';
import store from '@/store/index';
import { initApi } from '@/shared/ApiService';

initApi(store)

@Component({
  components: {
  },
  router,
  store
})
export default class App extends Vue {
  @Prop() username!: string
  // @Provide() id = store.state.user.user.id
}
