









import { Component, Prop, Vue } from 'vue-property-decorator'
import PachincoRegister from '@/components/PachincoRegister.vue'
import { useAuthService } from '@/shared/AuthService'
const authService = useAuthService();

@Component({
  components: {
    PachincoRegister
  }
})
export default class PachincoView extends Vue { 
  @Prop() id!: number
  @Prop() username!: string
  async logOut(): Promise<void> {
    await authService.logout();
  }
}
