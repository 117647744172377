






























































import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import axios from 'axios';
import * as Config from '../config';
import BotConfig from '@/components/BotConfig.vue'
import BotRequest from '@/components/BotRequest.vue'
import store from '@/store';
// import { router } from '@/router';
import { useAuthService } from '@/shared/AuthService'
const authService = useAuthService();

type Item = { 
    id: number, 
    hostname: string, 
    cluster: string,
    node: string,
    ip: string,
    owner: {
      id: number,
      email: string
    }
    nickname: string, 
    exchange: string, 
    created_at: Date, 
    registered_at: Date,
    closed_at: Date
}
export type Schema = {
  hostname: string,
  node: string,
  ip: string,
  strategy: string,
  max_open_trades: number,
  stake_amount: string | number,
  exchange: {
    name: string,
    key: string,
    secret: string,
    pair_blacklist: string[],
    pair_whitelist: string[]
  },
  stake_currency: string,
  stoploss: number,
  tradable_balance_ratio: number,
  ask_strategy: {
    sell_profit_only: boolean,
    sell_profit_offset: number
  },
  dry_run: boolean,
  minimal_roi: {
    "0": number
  }
}

@Component({
  components: {
    BotConfig,
    BotRequest
  }
})
export default class Dashboard extends Vue {
  $refs!: {
    config: HTMLFormElement
  }
  @Prop(String) readonly username!: string
  // @Prop(Number) readonly id!: number
  @Provide() items = []
  @Provide() bot_id = 0
  @Provide() overlay = false
  @Provide() admin = false
  // @Provide() schema: Schema = null as unknown as Schema
  @Provide() schema = {
    hostname: '',
    node: '',
    ip: '',
    strategy: 'BbandRsi:base64',
    stake_amount: "unlimited",
    exchange: {
      name: '',
      key: '',
      secret: '',
      pair_blacklist: [],
      pair_whitelist: []
    },
    max_open_trades: 3, 
    stake_currency: 'KRW',
    stoploss: -0.15,
    tradable_balance_ratio: 0.99,
    ask_strategy: {
      sell_profit_only: false,
      sell_profit_offset: 0.003
    },
    dry_run: true,
    minimal_roi: {
      "0": 0.07
    }
  }

  @Provide() fields = [
    {
      key: 'index',
      label: '번호'
    },
    {
      key: 'hostname',
      label: 'Bot ID'
    },
    {
      key: 'nickname',
      label: '봇 이름'
    },
    {
      key: 'exchange',
      label: '거래소'
    },
    {
      key: 'owner',
      label: '주인장',
      formatter: (value: string, key: string, item: Item): string => {
        return item.owner.email
      }
    },
    {
      key: 'cluster',
      label: 'cluster'
    },
    {
      key: 'node',
      label: 'node'
    },
    {
      key: 'registered_at',
      label: '신청일',
      formatter: (value: string, key: string, item: Item): string => {
        return (new Date(item.registered_at).toLocaleDateString());
      }
    },
    {
      key: 'created_at',
      label: '배송일',
      formatter: (value: string, key: string, item: Item): string => {
        return item.created_at ? (new Date(item.created_at).toLocaleDateString()) : '';
      }
    },
    {
      key: 'closed_at',
      label: '반납일',
      formatter: (value: string, key: string, item: Item): string => {
        return item.closed_at ? (new Date(item.closed_at).toLocaleDateString()) : '';
      }
    },
    {
      key: 'status',
      label: '상태',
      formatter: (value: string, key: string, item: Item): string => {
        if (item.closed_at) { return '반납 완료'}
        return item.created_at ? '배송 완료' : '접수 완료';
      }
    },
    {
      key: 'config',
      label: '환경설정'
    },
    {
      key: 'gotoBot',
      laBel: '봇'
    },
    {
      key: 'returnBot',
      laBel: '반납'
    }
  ]
  async mounted (): Promise<void> {
    const info = authService.getLoginInfo();
    this.admin = info['admin'];
    await store.dispatch('bots/updateBots');
    this.items = this.$store.state.bots.items.data;
  }
  async retractBot(item: Item): Promise<void> {
    this.overlay = true;
    try {
      const headers = { Authorization: authService.getAccessToken() }
      await axios.delete(`${Config.Config.BotConfigAPI}/bot/retract/${item.id}`, { headers });
      await store.dispatch('bots/updateBots');
      this.items = this.$store.state.bots.items.data;
      this.overlay = false;
    } catch(err) {
      console.log(err);
      this.overlay = false;
    }
  }
 
  async configureBot(item: Item): Promise<void> {
    if ( item.node == null) {
      alert("여기서 처리할 수 없는 봇입니다");
      // this.$root.$emit('hide::modal', 'modal-scrollable');
      // this.$root.$emit('cancel::modal', 'modal-scrollable');
      location.reload();
    } else {
      this.overlay = true;
      try {
        const headers = { Authorization: authService.getAccessToken() }
        this.bot_id = item.id
        const res = await axios.get(`${Config.Config.BotConfigAPI}/bot/config/${item.id}`, { headers });
        this.schema = res.data;
        this.schema.hostname = item.hostname;
        this.schema.node = item.node
        if (this.schema.strategy != undefined) {
          const strategy = this.schema.strategy.split(':');
          this.schema.strategy = strategy[0];
        }
        this.schema.ip = item.ip
        this.overlay = false;
      } catch(error) {
        authService.removeLoginInfo()
        this.$router.push('/login')
        this.overlay = false;
      }
    }
    this.overlay = false;
  }

  cancelConfig(): void {
    this.overlay = false;
  }
  async handleBotRegistered(): Promise<void> {
    this.items = store.getters['bots/getBots']['data'];
  }
  gotoBot(item: Item): void {
    if (item.cluster !== null) {
      window.open(`https://${item.hostname}.${item.cluster}.bitsbee.io`, "_blank")
    } else {
      window.open(Config.Config.UiHost, "_blank");
    }
  }
  async returnBot(item: Item): Promise<void> {
    this.overlay = true;
    console.log(item);
    try {
      const headers = { Authorization: authService.getAccessToken() }
      await axios.put(`${Config.Config.BotConfigAPI}/bot/retract/${item.id}`, { headers });
      this.overlay = false;
    } catch(err) {
      console.log(err);
      this.overlay = false;
    } 
  }
  async submitConfig(): Promise<void> {
    this.$refs.config.submitConfig();
  }
}
