import { ActionContext } from 'vuex';
import { State } from '@/store/index';
import axios from 'axios';
import * as Config from '@/config';
import { useAuthService } from '@/shared/AuthService'
import { router } from '@/router';
const authService = useAuthService();

type Context = ActionContext<IBots, State>;

export type Bot = {
  botId: string,
  botState: string,
  mode: string
}

export interface IBots {
  items: Array<Bot>
}

export default { 
  namespaced: true,
  state: (): IBots => ({ items: Array<Bot>() }),
  getters: {
    getBots: (state: { items: Array<Bot> }): Array<Bot> => {
      return state.items;
    }
  },
  mutations: {
    UPDATE_BOTS(state: IBots, bots: Array<Bot>): void {
      state.items = bots;
    }
  },
  actions: {
    updateBots: async (context: Context): Promise<void> => {
      const headers = { Authorization: authService.getAccessToken() };
      try {
        const res = await axios.get(`${Config.Config.BotConfigAPI}/bot/list`, { headers });
        context.commit('UPDATE_BOTS', res.data);
      } catch(error){
        authService.removeLoginInfo()
        router.push('/login')
      }
    }
  }
}
