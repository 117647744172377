









import { Component, Prop, Vue } from 'vue-property-decorator'
import Dashboard from '@/components/Dashboard.vue'
import { useAuthService } from '@/shared/AuthService'
const authService = useAuthService();

@Component({
  components: {
    Dashboard
  }
})
export default class BitsBeeView extends Vue { 
  @Prop() id!: number
  @Prop() username!: string
  async logOut(): Promise<void> {
    await authService.logout();
  }
}
