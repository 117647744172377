import Vue from 'vue';
import Vuex from 'vuex';
import botsModule, { IBots } from '@/store/modules/bots'
import userModule, { IUser } from '@/store/modules/user'
import pachincosModule, { IPachincos } from '@/store/modules/pachincos'

Vue.use(Vuex);

export interface State {
  bots: IBots,
  user: IUser,
  pachincos: IPachincos
}

const store = new Vuex.Store<State>({
  modules: { 
    bots: botsModule,
    user: userModule,
    pachincos: pachincosModule
  }
})

export default store;
