















































































import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import { useAuthService } from '@/shared/AuthService'
import * as Config from '../config';
const defaultURL = Config.Config.BotConfigAPI
const authService = useAuthService();

@Component
export default class Login extends Vue {
  @Prop(String) email!: string
  @Provide() overlay = false;
  @Provide() auth =  {
        url: `${defaultURL}/auth/login`,
        username: '',
        password: ''
  }
  @Provide() nameState = null
  @Provide() urlState = null
  @Provide() errorMessage = ''
  @Provide() errorMessageCORS = false
  @Provide() inModal = false
  @Provide() isLoggedIn = false
  @Provide() alert = false

  $refs!: { form: HTMLFormElement }

  mounted(): void {
    this.isLoggedIn = authService.loggedIn()
    // const loginInfo = authService.getLoginInfo();
    // if (loginInfo['accessToken'] !== '') {
    //   this.emitLoginResult(true);
    //   this.isLoggedIn = true;
    // }
  }
  checkFormValidity(): boolean {
    const valid = this.$refs.form.checkValidity();
    return valid;
  }
  resetLogin(): void {
    this.auth.url = `${defaultURL}/auth/login`;
    this.auth.username = '';
    this.auth.password = '';
    this.nameState = null;
    this.errorMessage = '';
  }

  handleReset(evt: { preventDefault: () => void; }): void {
    evt.preventDefault();
    this.resetLogin();
  }

  handleOk(evt: { preventDefault: () => void; }): void {
    evt.preventDefault();
    this.handleSubmit();
  }
  async handleSubmit(): Promise<void> {
    if (!this.checkFormValidity()) {
      alert("뭔가 잘못되었습니다. 이메일주소와 비번을 잘 입력해 주세용...");
      return;
    }
    this.errorMessage = '';
    this.overlay = true;
    const res = await authService.login(this.auth);
    if (res) {
      this.emitLoginResult(true);
      this.$router.push('/pachinco');
      this.overlay = false;
    } else {
      this.emitLoginResult(false);
      this.alert = true;
      this.overlay = false;
    }
  }
  emitLoginResult(value: boolean): boolean {
    this.$emit('loginResult', value);
    return value;
  }
  async logOut(): Promise<void> {
    await authService.logout();
    location.reload();
  }
}
