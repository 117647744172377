import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)
const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    meta: { reload: true },
    component: () => import('@/views/BitsBeeView.vue')
  },
  {
    path: '/pachinco',
    component: () => import('@/views/PachincoView.vue')
  },
  { 
    path: '/login', 
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '*',
    redirect: '/login'
  }
]
 
export const router = new VueRouter({
  mode: 'history',
  routes
 })
